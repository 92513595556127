import Request from "./requset.js";
import RequestLod from "./requsetLod.js";
export function uploads(
  url,
  params = {},
  headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "multipart/form-data",
  }
) {
  return new Promise((resolve, reject) => {
    // console.log(params)
    Request({
      url: url,
      method: "POST",
      data: params,
      headers: headers,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
// get方法
export function get(val) {
  return Request({
    method: "get",
    url: val.apiUrl,
    params: val.params,
  });
}
// post方法
export function post(val) {
  return Request({
    method: "POST",
    url: val.apiUrl,
    data: val.params,
  });
}
// get方法
export function gets(val) {
  return RequestLod({
    method: "get",
    url: val.apiUrl,
    params: val.params,
  });
}
const cvbdApis = "https://cvbd-apis.chinaucv.com"; //大数据平台
// const cvbdApis='http://192.168.3.90:8080'//大数据平台
// ocr
export const ocrConsumer = (params) =>
  uploads(`${cvbdApis}/api/h5/ocrConsumer`, params);
export const uploadImage = (params) =>
  uploads(`${cvbdApis}/api/h5/uploadImage`, params);

// 旧版获取配置
export const getVehicleOptions = (params) =>
  post({
    apiUrl: `${cvbdApis}/api/h5/getVehicleOptions`,
    params,
  });
// 新版获取配置
export const getVehicleOption = (params) =>
  post({
    apiUrl: `${cvbdApis}/api/h5/getVehicleOption`,
    params,
  });
// 新版挂车验证
export const checkTrailer = (params) =>
  post({
    apiUrl: `${cvbdApis}/api/h5/checkTrailer`,
    params,
  });
// 新版vin码验证
export const checkVehicleParam = (params) =>
  post({
    apiUrl: `${cvbdApis}/api/h5/checkVehicleParam`,
    params,
  });
// 新版进口验证
export const checkVehicleImported = (params) =>
  post({
    apiUrl: `${cvbdApis}/api/h5/checkVehicleImported`,
    params,
  });
// 新版预挂车验证
export const checkVehiclePreSale = (params) =>
  post({
    apiUrl: `${cvbdApis}/api/h5/checkVehiclePreSale`,
    params,
  });
// 新版验证特殊车型
export const getVehicleSeries = (params) =>
  post({
    apiUrl: `${cvbdApis}/api/h5/getVehicleSeries`,
    params,
  });
// 新版验证特殊车型配置
export const getOtherOptions = (params) =>
  post({
    apiUrl: `${cvbdApis}/api/h5/getOtherOptions`,
    params,
  });
// 新版验证是否支付
export const getSinglePayStatus = (params) =>
  post({
    apiUrl: `${cvbdApis}/api/h5/getSinglePayStatus`,
    params,
  });

// 请求结果
export const consumerQuickValuation = (params) =>
  post({
    apiUrl: `${cvbdApis}/api/h5/consumerQuickValuation`,
    params,
  });

//  本地下载
export const newCreatePdf = (params) =>
  post({
    apiUrl: `${cvbdApis}/api/h5/newCreatePdf`,
    params,
  });

//图片上传
export const upPicture = (params) =>
  uploads(`${cvbdApis}/api/h5/upPicture`, params);

// 获取参数详情
export const getImportedVehicleOptions = (data) =>
  post({
    apiUrl: `${cvbdApis}/api/h5/getImportedVehicleOptions`,
    params: data,
  });
// 获取参数详情
export const createAppOrder = (data) =>
  post({
    apiUrl: `${cvbdApis}/api/h5/createAppOrder`,
    params: data,
  });
// // 获取发动机参数
// export const getVehicleOptions = (data) =>
//   post({
//     apiUrl: `${cvbdApis}/api/h5/getVehicleOptions`,
//     params: data,
//   });
// 评估师
// const apicgu = "http://192.168.3.90:9080";
const apicgu = "https://apicgu.chinaucv.com";
// 
//  电商
export const getSimpleTask = (data) =>
  gets({
    apiUrl: `${apicgu}/evaluation/task/getSimpleTask?taskId=${data.taskId}`,
  });
//  电商
export const getSimpleTechAppraisal = (data) =>
  gets({
    apiUrl: `${apicgu}/evaluation/task/getSimpleTechAppraisal?taskId=${data.taskId}`,
  });
//  新版认证图片接口
export const getVehicleImages_1_ = (data) =>
  gets({
    apiUrl: `${apicgu}/evaluation/task/getVehicleImages_2?taskId=${data.taskId}`,
  });
//  报告
export const details = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/task/getTaskPreview?taskId=${data.taskId}`,
  });
// 证书
export const certificateMessAge = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/cert/certificateMessAge?certId=${data.certId}`,
  });
//  报告
export const report = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/task/getTaskBaseInfo?taskId=${data.taskId}`,
  });
//  证书报告
export const certificateReport = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/certification/getCertificationDetail?id=${data.id}`,
  });
//  报告图片
export const reportImg = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/task/getVehicleImages?taskId=${data.taskId}`,
  });
//  报告基本参数
export const reportInfo = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/task/getVehicleBaseInfo?taskId=${data.taskId}`,
  });
//  报告基本信息页面参数
export const reportInfos = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/task/getVehicleDetail?taskId=${data.taskId}`,
  });
//  报告基本信息页面参数
export const getTechReport = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/task/getTechReport?taskId=${data.taskId}`,
  });
//  报告减值信息页面参数
export const getImpairment = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/task/getImpairment?taskId=${data.taskId}`,
  });
//  报告减值信息页面参数
export const getTaskVin = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/task/getTaskVin?taskId=${data.taskId}&checkStr=${data.checkStr}`,
  });
//  新版认证图片接口
export const getVehicleImages_ = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/task/getVehicleImages_2?taskId=${data.taskId}&certId=${data.certId}`,
  });
//  新版评估图片接口
export const getVehicleImages_1 = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/task/getVehicleImages_2?taskId=${data.taskId}`,
  });
//  新版评估图片接口
export const getVehicleImages_1s = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/prepare/getVehicleImages?id=${data.taskId}`,
  });
//  新版首页数据
export const getTaskBaseInfo_ = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/task/getTaskBaseInfo_2?taskId=${data.taskId}`,
  });
//  新版车辆信息数据
export const getVehicleDetail_ = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/task/getVehicleDetail_2?taskId=${data.taskId}`,
  });
//  新版车辆信息数据
export const getVehicleDetail_s = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/prepare/getVehicleDetail?id=${data.taskId}`,
  });
export const getDetails = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/prepare/getDetail?id=${data.taskId}`,
  });
//  新版车辆信息数据
export const getTechAppraisal = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/task/getTechAppraisal?taskId=${data.taskId}`,
  });
// 
//  车辆整备数据
export const getTechAppraisalPre = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/prepare/getTechAppraisal?id=${data.id}`,
  });
//  新版车辆信息数据 认证
export const certificationGetTechAppraisal = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/certification/getTechAppraisal?id=${data.id}`,
  });
//  新版复核车辆信息
export const getTaskReview = (data) =>
  get({
    apiUrl: `${apicgu}/evaluation/task/getTaskReview?taskId=${data.taskId}`,
  });
const cvbdAdmin = "https://cnuapi.chinaucv.com/evaluation";
//  查询报告
export const getTaskDetailByNumber = (data) =>
  post({
    apiUrl: `${cvbdAdmin}/eva/getTaskDetailByNumber`,
    params: data,
  });
