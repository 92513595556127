

/*
    网络请求
*/
import { Toast } from 'vant';
import axios from "axios";
import store from '@/store/index';
const requestLod = axios.create({
    timeout: 60000,
});
requestLod.interceptors.request.use(
    (config) => {
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);
requestLod.interceptors.response.use(
    (response) => {
        return response;
    },
    (err) => {
        const { response } = err;
        if (response) {
            switch (response.status) {
                case 400:
                    console.log(response.data);
                    break;
                case 401:
                    console.log(response.data);
                    break;
                case 403:
                    console.log(response.data);
                    break;
                case 404:
                    console.log(response.data);
                    break;
                default:
                    console.log(response.data);
                    break;
            }
        } else {
            Toast('网络出小差了~，稍后再试');
            console.log("请求中断了！！！");
        }
    }
);
let RequesLod = (params) => {
    return new Promise((resolve, reject) => {
        requestLod({
            ...params,
        })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
export default RequesLod;